@charset "utf-8";
/*!
Theme Name: asahi graphic art & design
Theme URI: http://www.asahi-g.com/
Author: oota general store
Author URI: https://y-is.jp/
Description: アサヒグラフィック用テーマ
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: asahi-g.com

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/
@import "css/_setting.css";
@import "css/_html5reset.css";
@import "css/_mixin.css";

@import "css/_pc.css";
@import "css/_sp.css";

* {
  box-sizing: border-box;
}


@keyframes purun {
  0%   { transform: scale(1.0, 1.0) translate(0%, 0%); }
  15%  { transform: scale(0.9, 0.9) translate(0%, 5%); }
  30%  { transform: scale(1.3, 0.8) translate(0%, 10%); }
  50%  { transform: scale(0.8, 1.3) translate(0%, -10%); }
  70%  { transform: scale(1.1, 0.9) translate(0%, 5%); }
  100% { transform: scale(1.0, 1.0) translate(0%, 0%); }
}


.pswp {
  z-index: 19999;
}
.pswp__bg {
  background-color: rgba(0,0,0,0.4);
}
