
@define-mixin clearFix {

    content:'';
    display:block;
    clear:both;

}

@define-mixin mainfont {
  font-family: '教科書ICA R','KyokaICAPro-Regular','Kyoukasho ICA Regular', sans-serif;
}

@define-mixin inner {
  width: var(--base_width);
  margin: 0 auto;
  padding: 0 20px;
}

@define-mixin inner-min {
  width: 840px;
  margin: 0 auto;
}

@define-mixin innerSp {
  padding: 0 20px;
}


/* center , flex-end , flex-start , space-around ,*/
@define-mixin flexSet $justify:space-between, $wrap:nowrap {
  display: flex;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@define-mixin flexBase $size {
  flex-basis: $size;
  max-width: $size;
}


/* background-ex */
@define-mixin imgBox $imgfile,$x:1 ,$display: block{
  background: resolve($imgfile) center center no-repeat;
  background-size: size($imgfile,$x);
  width: width($imgfile,$x);
  height: height($imgfile,$x);
  text-indent: -9999px;
  display: $display;
}

/* responsive bgimg */
@define-mixin respoImgBox $imgfile {
  display: block;
  background: resolve($imgfile) center top no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 0;
  /*padding-top: calc(100*(height($imgfile)/width($imgfile)));*/
}
