@media screen and (max-width: 767px) {
  html {
     font-size: 62.5%;
  }
  body {
    background-color: #fff;
    color: #333333;
    font-size: 15px;
    font-size: 1.5rem;
    font-family: var(--base_font);
    padding-top: 60px;
  }

  a {
    color: #000;
    text-decoration: underline;

  }

  .sp-hide {
    display: none !important;
  }
  .liststyle01 {
    list-style: none;
    text-indent: -1em;
    padding-left: 1em;
  }
  .backbtn {
    text-align: center;
    margin-top: 50px;
    a {
      display: inline-block;
      text-decoration: none;
      border: 1px solid #000;
      padding: 12px 70px;
      font-size: 1.2rem;
    }
  }

  .pageheader {
    background-color: #fff;
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;
    left: 0;

    ._inner {
      > h1 {
        padding: 8px 10px;
        border-bottom: 1px solid #000;
        a {
          @mixin imgBox head_logo01@2x.svg,2;
        }
      }
       nav {
         background-color: #fff;
         display: none;
         overflow: scroll;
         position: fixed;
         top: 37px;
         left: 0;
         width: 100%;
         height: 100%;
         padding-bottom: 37px;
        > ul {
          &.header_nav_main {
            border-bottom: 1px solid #999;

            a {
              display: block;
            }

            > li {
              border-top: 1px solid #999999;
              position: relative;
              width: 100%;

              &.haschild {
                background: resolve('head_navbg-close@2x.png') right 20px no-repeat;
                background-size: 29px 11px;


                &.open {
                  background-image: resolve('head_navbg-open@2x.png');
                }
              }

            >  a {
              background-position: 10px center;
              width: 100%;
              > span {
                position: absolute;
                text-indent: 0;
                top: 18px;
                left: 160px;
                color: #000;
              }
            }


              a {
                display: block;
                text-decoration: none;
                padding: 10px;
                height: 50px;


                &.active {
                  opacity: 0.7;
                }
              }

            }

            ul {
              display: none;
              width: auto;
              background-color: #fff;
              padding: 0 0 0 50px;
              border-top: 1px solid #999;

              .open & {
                display: block;
              }
              li {
                padding: 0;
                width: auto;
                max-width: none;
                color: #666666;
                border-top: 1px solid #999;
                border-left: 1px solid #999;

                &:first-child {
                   border-top: none;
                }

                a {
                  width: auto;
                  display: block;
                  padding: 15px 20px;
                  word-break: keep-all;
                  white-space: nowrap;
                  color: #666666;
                }
              }
            }
          }

          &.header_nav_lang {
            display: none;


            a {
              display: block;
              text-decoration: none;

              &.active {
                opacity: 0.5;
              }
            }

          }
          &.header_nav_insta {
            padding: 10px;
            a {
              display: block;
              text-decoration: none;

              &:hover , &.active {
                opacity: 0.7;
              }
            }
          }

        }
      }
    }

    .spnav_btn {
      width: 20px;
      height: 18px;
      position: absolute;
      top: 8px;
      right: 10px;
      span {
        width: 20px;
        height: 3px;
        display: block;
        background-color: #999999;
        position: absolute;
        top: 0;
        left: 0;

        transition: all 200ms 0s ease;

        + span {
          top: 7px;
          + span {
            top: 14px;
          }
        }
      }

      &.open {
        span {
          transform: translateX(0px) translateY(7px) rotate(45deg);
          width: 20px;

          + span {
            transform: translateX(0px) translateY(0px) rotate(45deg);
            + span {
              transform: translateX(0px) translateY(-7px) rotate(-45deg);
            }
          }
        }
      }
    }

  }
  .header_nav {
    &_li01 {
      @mixin imgBox head_nav01@2x.svg,2;
    }
    &_li02 {
      @mixin imgBox head_nav02@2x.svg,2;
    }
    &_li03 {
      @mixin imgBox head_nav03@2x.svg,2;
    }
    &_li04 {
      @mixin imgBox head_nav05@2x.svg,2;
    }
    &_li05 {
      @mixin imgBox head_nav04@2x.svg,2;
    }
    &_li06 {
      @mixin imgBox head_nav06@2x.svg,2;
    }
    &_li07 {
      @mixin imgBox head_bogo01.svg;
      height: 21px;
      margin: 0 10px;
    }
    &_li08 {
      @mixin imgBox head_bogo02.svg;
      height: 21px;
      margin: 0 10px;
    }
    &_li09 {
      @mixin imgBox head_insta.svg,2;
    }
    &_li10 {
      @mixin imgBox head_group01@2x.svg,2;
    }
    &_li11 {
      @mixin imgBox head_group02@2x.svg,2;
    }
    &_li12 {
      @mixin imgBox head_group00@2x.svg,2;
    }

    &_group {
      li {
        text-align: center;
        border-top: 1px solid #ccc;
        padding: 15px 0 !important;
        color:#999999;
        line-height: 1.5;

        small {
          font-size: 1.2rem;
        }
        &:first-child {
          border: none;
        }
        a {

        }
      }


    }
  }

  .spheader_nav_lang {
    @mixin flexSet center;
    border-bottom: 1px solid #000;
    background-color: #fff;
    z-index: 9;
    position: fixed;
    top: 38px;
    left: 0;
    width: 100%;
    li {
      list-style: none;
    }

    a {
      display: block;
      text-decoration: none;

      &.active {
        opacity: 0.7;
      }
    }

  }



  .pagefooter {
    padding: 40px 0 20px;
    &_copyright {
      &_text {
        text-align: center;
        font-size: 1rem;
      }
    }

    &_totopBtn {
      a {
        @mixin imgBox totop_btn@2x.png,2;
        position: fixed;
        bottom: 20px;
        right: 20px;

      }
    }
  }


  .indhero {
    width: 100%;
    ul {
      width: 100%;
      li {
        a {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .home {
    .main {
      > ._inner {
        padding: 0 20px;
      }

      &_cont {
        h1 {
          margin-top: 30px;
          @mixin mainfont;
          font-size: 1.8rem;
          line-height: 2;
          text-align: center;
        }

        &_nav {
          margin-top: 35px;
          dl {
            margin-top: 30px;
            dt {
              text-align: center;

              span {
                font-size: 2.2rem;
                @mixin mainfont;
                border-bottom: 2px solid #000;
                line-height: 1.5;
                display: inline-block;
                padding: 0 5px;
              }

            }
            dd {
              margin-top: 20px;
              a {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .pagetitlebox {
    text-align: center;
    h1 {
      @mixin mainfont;
      font-size: 2.9rem;
      border-bottom: 2px solid #000;
      display: inline-block;
      line-height: 1.2;

      + p {
        text-align: left;
        @mixin mainfont;
        margin-top: 1.4em;
        line-height: 2;
      }
    }
  }


  .artists {
    &_index {
      &._inner {
        padding: 30px 20px;
      }

      &_list {
        margin-top: 30px;
        ul {
          &:after {
            @mixin clearFix;
          }

          li {
            float: left;
            margin-right: 6%;
            margin-top: 20px;
            list-style: none;
            text-align: center;
            line-height: 1.5;
            width: 47%;

            &:nth-child(2n) {
              margin-right: 0;

            }
            a {
              text-decoration: none;
              @mixin mainfont;
              .name_en {
                font-size: 1.2rem;
              }
              .name_jp {
                font-size: 1.9rem;
                letter-spacing: .5em;
              }

              img {
                width: 100%;
                height: auto;
              }

            }
          }
        }
      }
    }
  }

  .inquiry {
    &_main {
      &._inner {
        padding: 60px 20px 0;
        line-height: 1.8;
        font-size: 1.1rem;
      }

      .lead {
        margin-top: 50px;
      }

      &_telbox {
        margin: 50px 0;
        text-align: center;
        ul {
          @mixin flexSet center;
          li {
            list-style: none;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 0 10px;
          }
        }

        &_btn {
          margin-top: 20px;
          a {
            display: inline-block;
            border: 3px solid #000;
            padding: 0px 20px;
            font-size: 1.5rem;
            font-weight: bold;
            text-decoration: none;
            letter-spacing: .1em;
          }
        }
      }

      &_listsmall {
        font-size: 1.2rem;
      }

      &_notice {
        margin-top: 60px;
      }
    }

    &_steps {
      margin-top: 60px;

      > h1 {
        @mixin mainfont;
        font-size: 2.5rem;
        font-weight: normal;
      }

      > section {
        margin-top: 30px;
        > h1 {
          font-size: 1.4rem;
          font-weight: normal;
        }
        > p {
          margin-top: 15px;

          strong {
            font-weight: bold;
          }
        }
      }
    }

    &_form {
      margin-top: 60px;
      > h1 {
        @mixin mainfont;
        font-size: 2.8rem;
        font-weight: normal;

        + ul {
          margin-top: 40px;
        }
      }

      form {
        li {
          list-style: none;
          margin-top: 40px;
        }
        input {
          font-size: 1.6rem;
          padding: 15px 20px;
          border: 1px solid #999;
          width: 100%;
        }
        textarea {
          font-size: 1.6rem;
          padding: 15px 20px;
          border: 1px solid #999;
          width: 100%;
        }

        .submit {
          margin-top: 30px;
          text-align: center;
          input {

            font-weight: bold;
            color: #fff;
            background-color: #666666;
            cursor: pointer;
          }
        }
      }

    }
  }
  .about {
    &_message {
      &._inner {
        padding: 60px 20px 0;
        line-height: 1.8;
        font-size: 1.2rem;
      }

      &_cont {
        margin-top: 30px;
      }
      &_pict {
        img {
          width: 100%;
          height: auto;
        }
      }
      &_text {
        @mixin mainfont;
        margin-top: 10px;
      }
    }

    &_profile {
      &._inner {
        padding: 60px 20px 0;
        line-height: 1.8;
        font-size: 1.1rem;
      }

      &_cont {
        margin-top: 30px;

        dl {
          margin-top: 10px;

          dt {
            border-bottom: 1px dotted #9FA0A0;
        }
          dd {
            margin-top: 5px;
          }
        }
      }
    }

    &_map {
      margin-top: 30px;
      font-size: 1.2rem;
      iframe {
        height: 200px;
      }
    }
  }

  .design {
    &_main {
      &._inner {
        padding: 60px 20px 0;
        line-height: 1.8;
        font-size: 1.2rem;
      }
    }
    .artisttitlebox {
      text-align: center;
      @mixin mainfont;
      line-height: 1.6;
      h1 {
        display: inline-block;
        border-bottom: 1px solid #000;
        margin-bottom: 20px;

      }
      .name_en {
        font-size: 1.4rem;
      }
      .name_jp {
        font-size: 2.9rem;
        letter-spacing: 0.2em;
      }
      .addsent {
        margin-top: 1.5em;

      }
      .forlist {
        margin-top: 40px;
        a {
          width: 80%;
          display: inline-block;
          font-size: 1.4rem;
          border: 1px solid #000;
          padding: 15px 0;
          text-decoration: none;
        }
      }
    }

    &_list {
      margin-top: 30px;
      &:after {
        @mixin clearFix;
      }

      figure {

          float: left;
          margin-right: 6%;
          margin-top: 20px;
          list-style: none;
          line-height: 1.5;
          width: 47%;

          &:nth-child(2n) {
            margin-right: 0;

          }
          a {
            text-decoration: none;
            .name_en {
              font-size: 1.2rem;
            }
            .name_jp {
              font-size: 1.9rem;
              letter-spacing: .5em;
            }

            img {
              width: 100%;
              height: auto;
            }

          }

          figcaption {
            font-size: 1.2rem;
          }

      }

      &_backbtn {
        text-align: center;
        margin-top: 40px;

        a {
          display: inline-block;
          text-decoration: none;
          border: 1px solid #000;
          padding: 10px;
          text-align: center;
          width: 100%;
        }
      }
    }



  }

  .grouptop {

    .pageheader-group {
      background-color: #fff;
      position: fixed;
      z-index: 9999;
      width: 100%;
      top: 0;
      left: 0;

      ._inner {
        > h1 {
          padding: 10px 0;
          border-bottom: 1px solid #000;
          a {
            @mixin imgBox asahig_logo@2x.png,2;
            margin: 0 auto;
          }
        }
      }

     nav {
       background-color: #fff;
       display: none;
       overflow: scroll;
       position: fixed;
       top: 37px;
       left: 0;
       width: 100%;
       height: 100%;
       padding-bottom: 37px;
      }

      .spnav_btn {
        width: 20px;
        height: 18px;
        position: absolute;
        top: 8px;
        right: 10px;
        span {
          width: 20px;
          height: 3px;
          display: block;
          background-color: #999999;
          position: absolute;
          top: 0;
          left: 0;

          transition: all 200ms 0s ease;

          + span {
            top: 7px;
            + span {
              top: 14px;
            }
          }
        }

        &.open {
          span {
            transform: translateX(0px) translateY(7px) rotate(45deg);
            width: 20px;

            + span {
              transform: translateX(0px) translateY(0px) rotate(45deg);
              + span {
                transform: translateX(0px) translateY(-7px) rotate(-45deg);
              }
            }
          }
        }
      }

      .header_nav {
        &_li01 {
          @mixin imgBox asahig_spnav01@2x.png,2;
        }
        &_li02 {
          @mixin imgBox asahig_spnav02@2x.png,2;
        }
        &_li03 {
          @mixin imgBox asahig_spnav03@2x.png,2;
        }
        &_li04 {
          @mixin imgBox asahig_spnav04@2x.png,2;
        }
        &_li05 {
          @mixin imgBox asahig_spnav05@2x.png,2;
        }


        &_group {
          border-bottom: 1px solid #ccc;

          li {
            text-align: center;
            border-top: 1px solid #ccc;
            padding: 15px 0 !important;
            color:#999999;
            line-height: 1.5;
            position: relative;
            font-size: 1.2rem;

            &:first-child {
              border: none;
            }
            >  a {
              background-position: 10px center;
              width: 100%;
              > span {
                position: absolute;
                text-indent: 0;
                top: 12px;
                left: 140px;
                color: #000;
              }
            }
          }


        }
      }


    }

    &_specialtes {
      width: 30px;
      height: 29px;
      margin: 0 auto;
      position: relative;
      span {
        display: block;
        width: 2px;
        height: 29px;
        background-color: #000;
        position: absolute;

        &:first-child {
          top:0;
          left:0;
          transition: all 2000ms 0s ease;
          transform: translate(-250px,-130px) rotate(-40deg) scale(1,3);
          opacity: 0;
          &.onload {
            transform: translate(0,0) rotate(0) scale(1,1);
            opacity: 1;
          }
          & + span {
            left: 9px;
            transform: translate(-50px,-130px) rotate(-20deg) scale(1,3);
            transition: all 2000ms 200ms ease;
            opacity: 0;
            &.onload {
              transform: translate(0,0) rotate(0) scale(1,1);
              opacity: 1;
            }
          }
          & + span + span {
            left: 19px;
            transform: translate(250px,-130px) rotate(20deg) scale(1,3);
            transition: all 2000ms 400ms ease;
            opacity: 0;
            &.onload {
              transform: translate(0,0) rotate(0) scale(1,1);
              opacity: 1;
            }
          }
          & + span + span + span{
            left: 28px;
            transform: translate(50px,-130px) rotate(40deg) scale(1,3);
            transition: all 2000ms 600ms ease;
            opacity: 0;
            &.onload {
              transform: translate(0,0) rotate(0) scale(1,1);
              opacity: 1;
            }
          }

        }
      }
    }


    &_cont {
      padding: 0 20px;

    }

    &_title {
      @mixin imgBox asahig_catch@2x.png,2;
      margin: 15px auto;
    }
    &_copy {
      margin: 30px auto 40px;
      line-height: 2;
      font-size: 1.2rem;
    }

    &_nav {
      background: resolve('asahig_compbg01@2x.png') center top repeat-y;
      background-size: cover;
      background-color: #F0F0F0;

      ._inner {
        padding: 1px 42px 40px;

      }

      a {
        display: block;
        background: resolve('asahig_gname_bg01@2x.png') center top no-repeat;
        width: 100%;
        height: 0;
        padding-top: 48.8888889%;
        text-decoration: none;
        background-size: contain;
        margin-top: 40px;
        position: relative;

        dl {
          position: absolute;
          top:0;
          left:0;
          width: 100%;
          padding: 8%;
          dt {
            width: 100%;
            height: 0;
            background-repeat: no-repeat;
            background-position: center;
            text-indent: -9999px;
            padding-top: 16.4588529%;
            background-size: contain;

            &.art {
              background-image: resolve('asahig_gname01@2x.png');
            }
            &.human {
              background-image: resolve('asahig_gname02@2x.png');
            }
            &.sds {
              background-image: resolve('asahig_gname03@2x.png');
            }
            &.riverwest {
              background-image: resolve('asahig_gname04@2x.png');
            }
          }
          dd {
            text-align: center;
            margin-top: 15%;
          }
        }

        &.disable {
          cursor: default;
          background-image: resolve('asahig_gname_bg03@2x.png');
          dt {
            opacity: 0.5;
          }

        }
      }
    }

    &_access {
      padding: 30px;
      margin: 0 auto;
      text-align: center;
      a {
        background: resolve(asahig_access@2x.png) center center no-repeat;
        background-size: 80% auto;
        text-indent: -9990px;
        display: inline-block;
        padding: 10px 20px;
        border: 2px solid #000;
        width: 120px;




      }
    }
  }


  .artist {
    &_main {
      &._inner {
        padding: 0px 20px;
      }

    }

    .hero {
      width: 100%;
      height: 0;
      padding-top: 70%;

      &-typea {
        position: relative;
        overflow: hidden;
        > div {
          position: absolute;
          top: 0;
          left :0;
          display: none;
          width: 100%;
          height: 100%;
          text-align: center;
          img {
            width: auto;
            height: 100%;
          }

          transition: all 2250ms 0s ease;
          filter: grayscale(100);
          transform: scale(1.3,1.3);

          &.animate {
            filter: grayscale(0);
            transform: scale(1,1);
          }

          &:first-child {
            display: block;
          }
        }
      }

      &-typeb {
        position: relative;
        overflow: hidden;
        > div {
          position: absolute;
          top: 0;
          left :0;
          width: 100%;
          height: 100%;
          text-align: center;
          img {
            width: auto;
            height: 100%;
          }
          &:first-child {
          }
        }
      }

      &-typec {
        width: 100%;
        position: relative;
        overflow: hidden;
        background: resolve('typec_bg01.png') center center no-repeat;
        background-size: cover;

        &_cover {
          width: 500%;
          height: 100%;
          position: absolute;
          top:0;
          left:0;
          > div {
            float: left;
            padding: 20px;

            height: 100%;



            img {
              width: auto;
              height: 100%;
            }

          }
        }
      }

    }


    .artisttitlebox {
      margin-top: 30px;
      text-align: center;
      @mixin mainfont;
      line-height: 1.6;
      .name_en {
        font-size: 1.4rem;
      }
      .name_jp {
        font-size: 3.3rem;
        letter-spacing: 0.2em;
      }
      .addsent {
        margin-top: 1.5em;

      }
      .forlist {
        margin-top: 40px;
        a {
          width: 80%;
          display: inline-block;
          font-size: 1.4rem;
          border: 1px solid #000;
          padding: 15px 0;
          text-decoration: none;
        }
      }
    }

    &_details {
      margin-top: 50px;
      line-height: 1.875;

      &_text {
        font-size: 1.1rem;

        p {
          margin-top: 3em;

          &:first-child {
            margin-top: 0;
          }
        }
        .biography {


          dt {
            margin-top: 1em;
          }
          dd {
            width: 100%;
          }
        }

        .collabo {
          display: none;
        }

      }
      &_img {
        margin-top: 50px;

          &:after {
            @mixin clearFix;
          }

          figure {
            float: left;
            margin-right: 6%;
            margin-top: 20px;
            list-style: none;
            line-height: 1.5;
            width: 47%;

            &:nth-child(2n) {
              margin-right: 0;

            }
            a {

              img {
                width: 100%;
                height: auto;
              }

            }
            figcaption {
              text-decoration: none;
              font-size: 1.1rem;
            }
          }

          .forlist {
            clear: left;
            padding-top: 40px;
            text-align: center;
            a {
              width: 240px;
              display: inline-block;
              font-size: 1.4rem;
              border: 1px solid #000;
              padding: 5px 0;
              text-decoration: none;
              @mixin mainfont;
            }
          }


        + .collabo {
          margin-top: 40px;

          .my-gallery {
            margin-top: 40px;
          }

          figure:not(:first-child) {
            display: none;
          }

          a {
            font-family: var(--base_font_serif);
            display: block;
            background: resolve('artist_btn01@2x.png') left top no-repeat;
            width: 240px;
            height: 60px;
            background-size: 240px 60px;
            margin: 0 auto;
            text-align: center;
            text-decoration: none;
            font-size: 1.6rem;
            line-height: 1.3;
            padding-top: 8px;
            span {
              font-size: 1.2rem;
            }
            img {
              display: none;
            }
          }
        }


      }
    }
  }


  .character , .page-character {
    background: resolve('charabg01@2x.png') center top no-repeat;
    background-size: 100% auto;
    background-color: #FFE200;

    &_index {
      &._inner {
        padding: 30px 20px;
      }

      .pagetitlebox {
        p {
          margin-top: 20px;
        }
      }

      &_list {
        ul {
          margin-top: 60px;
          padding: 0 30px;

          li {
            list-style: none;
            text-align: center;
            margin-top: 30px;

            a {
              text-decoration: none;
              font-size: 1.3rem;
              color: #666666;

              &:hover {
                opacity: 1;
              }


            }
            img {
              width: 100%;
              height: auto;
              background-color: #fff;
              border-radius: 50%;
              padding: 20px;
              margin-bottom: 10px;
              margin-top: 20px;
              transform-origin: 50% 50%;

              &:hover {
                animation-name: purun;
                animation: purun 0.8s linear 0s 1;
              }

            }



            span {
              display: none;

            }
          }
        }
      }
    }
  }

  .charadetail {

    &._inner {
      padding-top: 30px;
    }

    .charatitle {
      text-align: center;
      p {
        @mixin mainfont;
      }
      h1 {
        margin: 10px 0 30px;
        img {
          width: 100%;
          height: auto;
        }
      }

    }
    &_img {
      img {
        width: 100%;
        height: auto;
      }
    }

    &_text {
      margin: 30px 0;
      padding: 0 20px;

      .cody & ,
      .shaklee & ,
      .shiraishi & {
        text-align: center;
        line-height: 2;
        font-size: 1.1rem;
      }
      .mataby & {
        line-height: 2;
        font-size: 1.1rem;
      }
      .chattie & {
        line-height: 2;
        font-size: 1.1rem;
        p {
          margin-top: 2em;

          &:first-child {
            margin-top: 0;
          }
        }
      }

    }

    &_banner {
      margin-top: 30px;
      ul {
        li {
          margin-top: 30px;

          a {
            text-decoration: none;

            img {
              width: 100%;
              height: auto;
            }
          }

        }
      }
    }

    &_items {
      margin-top: 30px;
      padding: 0 20px;
      &:after {
        @mixin clearFix;
      }

      figure {
        float: left;
        margin-right: 6%;
        margin-top: 20px;
        line-height: 1.5;
        width: 47%;

        &:nth-child(2n) {
          margin-right: 0;

        }

        a {
          text-decoration: none;

          img {
            width: 100%;
            height: auto;
            border: 1px solid #ccc;
          }

        }
      }

      figcaption {
        font-size: 1.1rem;
      }


    }
    .shiraishi_familytree {
      padding: 0 20px;
      img {
        width: 100%;
        height: auto;
        margin-top: 30px;
      }
    }

  }


}
